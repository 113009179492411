import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import B2BLogoIcon from "../../../../static/B2BLogo.png"
import B2BContactForm from "../B2BContactForm"

const B2BContactSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0px 5px;
  padding: 20px;
  background: #f0f0f0;
  border: 1px solid #262626;

  @media (max-width: 600px) {
    margin: 5px 0px 20px;
  }
`

const TopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 27px;
  position: relative;
  margin-bottom: 10px;
`

const B2BLogo = styled.img`
  width: 100%;
  max-width: 32px;
  margin: 0px 10px 0px 0px;
`

const Title = styled.p`
  font-size: 17px;
  line-height: 1.2;
  color: #262626;
  font-family: "Museo";
  font-weight: 500;
  margin: 0px 0px 10px;
`

const OpenClose = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 25px;
  height: 25px;
  border: 1.5px solid #111;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: #111;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubTitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  color: #262626;
  font-family: "Gotham Book";
  font-weight: 500;
  margin: 0px 0px 15px;

  strong {
    font-family: "Gotham";
    font-weight: bold;
  }
`

const ContactButton = styled.button`
  width: 100%;
  max-width: 220px;
  margin: 0px auto;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  background: #262626;
  padding: 10px 20px;
  cursor: pointer;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`

const B2BContactSection = ({ intl }) => {
  const [showContactForm, setShowContactForm] = useState(false)
  return (
    <B2BContactSectionWrapper className="showroom-widget">
      <TopSection>
        <B2BLogo src={B2BLogoIcon} />
        <Title>Une déploiement pour votre entreprise ?</Title>
        <OpenClose onClick={() => setShowContactForm(!showContactForm)}>
          {showContactForm ? "-" : "+"}
        </OpenClose>
      </TopSection>
      <SubTitle>
        PERCKO for Professionals équipe plus de 2000 entreprises.
      </SubTitle>
      <SubTitle>
        Pour des commandes de <strong>5 chaises et plus</strong>, contactez
        l'équipe dédiée.
      </SubTitle>
      {!showContactForm && (
        <ContactButton onClick={() => setShowContactForm(true)}>
          Nous contacter
        </ContactButton>
      )}
      {showContactForm && <B2BContactForm />}
    </B2BContactSectionWrapper>
  )
}

export default injectIntl(B2BContactSection)
